import React, {useEffect} from 'react';
import '../App.css';
import {
    Routes,
    Route, HashRouter
} from "react-router-dom";
import WelcomePage from "./WelcomePage";
import LKPage from "./LKPage";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import Header from "./Header";
import {useAppDispatch, useAppSelector} from "../store/store";
import {fetchUser, selectAuthLoading, selectReady, setReady, setTokens} from "../store/authSlice";
import TeacherPage from "./TeacherPage";
import TeacherList from "./TeacherList";

function App() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if(token){
            dispatch(setTokens({token, refreshToken: localStorage.getItem('refreshToken') || ''}))
            dispatch(fetchUser())
        } else {
            dispatch(setReady(true))
        }
    }, [dispatch]);

    const ready = useAppSelector(selectReady);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#284481'
            }
        }
    });

    return (
        <div>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Header/>
            </ThemeProvider>
            {ready && <HashRouter>
                <Routes>
                    <Route path="/" element={<WelcomePage/>}/>
                    <Route path="/lk" element={<LKPage/>}/>
                    <Route path="/teacher/:teacher" element={<TeacherPage/>}/>
                    <Route path="/admin" element={<TeacherList/>}/>
                </Routes>
            </HashRouter> }
        </div>
    );
}

export default App;
