import {
    Button,
    Card,
    CardContent,
    Container, FormControl,
    FormControlLabel, FormLabel, Radio, RadioGroup,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../store/store";
import {fetchUpdateUser, selectUser} from "../store/authSlice";
import React, {useState} from "react";

const ProfileEdit = () => {
    const user = useAppSelector(selectUser);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [thirdName, setThirdName] = useState<string>('');
    const [role, setRole] = useState<string>('student');
    const dispatch = useAppDispatch();

    const save = () => {
      dispatch(fetchUpdateUser({firstName, lastName, thirdName, role}))
    };

    return <Stack>
        <Card variant={'outlined'}>
            <CardContent>
                <Typography typography={'h6'} mb={2}>Добро пожаловать, {!firstName && <span>человек</span>} {firstName} {lastName}! Заполните профиль</Typography>
                <Stack  spacing={2}>
                    <TextField disabled={true} label={'Телефон'} defaultValue={user?.phone} />
                    <TextField  onChange={(e) => setLastName(e.target.value)} label={'Фамилия'} />
                    <TextField  onChange={(e) => setFirstName(e.target.value)}  label={'Имя'} />
                    <TextField  onChange={(e) => setThirdName(e.target.value)} label={'Отчество'} />
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Кто Вы?</FormLabel>
                    <RadioGroup value={role} onChange={(e) => {setRole(e.target.value)}}
                        aria-label="gender"
                        defaultValue="student"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="student" control={<Radio />} label="Ученик" />
                        <FormControlLabel value="teacher" control={<Radio />} label="Учитель" />

                    </RadioGroup>
                    </FormControl>
                    <Button onClick={() => save()} variant={'contained'} >Сохранить</Button>

                </Stack>

            </CardContent>
        </Card>

    </Stack>
}
export default ProfileEdit;
