import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../store/store";
import {clearTeacher, fetchTeacher, selectTeacher} from "../store/studentSlice";
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Container,
    Grid,
    Link, Skeleton,
    Typography
} from "@mui/material";
import moment from 'moment'
import {PeopleOutline} from "@mui/icons-material";
import {selectUser} from "../store/authSlice";
import LessonScheduler from "./LessonScheduler";
import {join} from "../api/schedule";
import SessionList from "./SessionList";
import TeacherStatus from "./TeacherStatus";

type Props = {}

const TeacherPage = (props: Props) => {
    let params = useParams<{ teacher: string }>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const teacher = useAppSelector(selectTeacher);
    const user = useAppSelector(selectUser);





    useEffect(() => {
        if (!params.teacher) {
            navigate('/lk');
        } else {
            dispatch(clearTeacher())
            dispatch(fetchTeacher({id: params.teacher}));

        }
    }, [params]);


    const teacherAdmin = <div>
        <LessonScheduler/>
    </div>

    return teacher ? (<Container>
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href={'#/lk'}>
                Список учителей
            </Link>
            <Typography color="text.primary">{teacher.lastName}&nbsp;{teacher.firstName.slice(0, 1)}.&nbsp;{teacher.thirdName.slice(0, 1)}.</Typography>
        </Breadcrumbs>
        <Typography marginBottom={1} display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                <span style={{fontSize: 24, fontWeight: 'bold', marginRight: 10}}>
                    {teacher.lastName}&nbsp;{teacher.firstName} {teacher.thirdName}
                </span> <Chip size={'small'} label={moment(teacher.lastVisit).fromNow()}/>

        </Typography>
        <Grid item sm={12} xs={12}>
            {teacher._id === user?._id && teacherAdmin}
        </Grid>
        {teacher?._id !== user?._id && <TeacherStatus/>}
        <h2>Прошедшие занятия</h2>
        <SessionList teacherId={teacher._id}/>

    </Container>) : <Container>
        <Skeleton animation="wave" />
        <Typography variant="h2"><Skeleton width={'70%'} animation="wave" /></Typography>
        <Card variant={'outlined'}>
            <CardContent>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton height={50} animation="wave" />
            </CardContent>
        </Card>
    </Container>
}

export default TeacherPage;
