import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "./store";
import axiosApp from "../axiosApp";
import {confirmCode, getUser, sendCode, updateUser} from "../api/auth";
import {User} from "../api/types/authApiTypes";

interface AuthState {
    refreshToken: string,
    token: string,
    code: string,
    smsToken: string,
    phone: string,
    user?: User,
    codeError?: string,
    loading: boolean,
    ready: boolean,
}

// Define the initial state using that type
const initialState: AuthState = {
    refreshToken: '',
    token: '',
    code: '',
    phone: '',
    smsToken: '',
    codeError: undefined,
    user: undefined,
    loading: false,
    ready: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCode: (state, action: PayloadAction<string>) => {
            state.code = action.payload;
        },
        setPhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload;
        },
        setReady: (state, action: PayloadAction<boolean>) => {
            state.ready = action.payload;
        },
        setTokens: (state, action: PayloadAction<{ token: string, refreshToken: string }>) => {
            state.token = action.payload.token
            state.refreshToken = action.payload.refreshToken
            axiosApp.defaults.headers.common['Authorization'] = 'Bearer ' + action.payload.token;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.user = action.payload;
            state.loading = false;
            state.ready = true;
        })

        builder.addCase(fetchUser.rejected, (state, action) => {
            state.loading = false;
            state.ready = true;
        })

        builder.addCase(fetchCode.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchConfirmCode.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchConfirmCode.rejected, (state, action) => {
            state.codeError = 'Неверный код. Введите ПОСЛЕДНИЕ 4 цифры номера, который вам позвонил. Если вы указали не тот номер - обновите страницу и попробуйте еще раз.'
            state.loading = true;
        })

        builder.addCase(fetchUser.pending, (state, action) => {
            state.loading = true;
        })

        builder.addCase(fetchCode.fulfilled, (state, action) => {
            state.smsToken = action.payload.token;
            state.loading = false;
        })

        builder.addCase(fetchUpdateUser.fulfilled, (state, action) => {
            state.user = action.payload;
            state.ready = true;
        })

        builder.addCase(fetchConfirmCode.fulfilled, (state, action) => {
            state.token = action.payload.token;
            state.refreshToken = action.payload.refreshToken;
            state.loading = false;
            localStorage.setItem('token', action.payload.token)
            localStorage.setItem('refreshToken', action.payload.refreshToken)
            axiosApp.defaults.headers.common['Authorization'] = 'Bearer ' + action.payload.token;
        })
    },
})

export const fetchUser = createAsyncThunk(
    'getUser',
    getUser
)

export const fetchCode = createAsyncThunk(
    'getCode',
    sendCode
)

export const fetchConfirmCode = createAsyncThunk(
    'confirmCode',
    confirmCode
)

export const fetchUpdateUser = createAsyncThunk(
    'updateUser',
    updateUser
)


export const { setCode, setTokens, setPhone, setReady } = authSlice.actions

export const selectCode = (state: RootState) => state.auth.code;
export const selectSmsToken = (state: RootState) => state.auth.smsToken;
export const selectUser = (state: RootState) => state.auth.user;
export const selectAuthLoading = (state: RootState) => state.auth.loading;
export const selectReady = (state: RootState) => state.auth.ready;
export const selectCodeError = (state: RootState) => state.auth.codeError;
export const selectPhone = (state: RootState) => state.auth.phone;

export default authSlice.reducer

