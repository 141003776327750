import {Button, Card, CardContent, Chip, CircularProgress, Grid} from "@mui/material";
import moment from "moment";
import {PeopleOutline} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../store/store";
import {fetchTeacher, selectTeacher} from "../store/studentSlice";
import {selectUser} from "../store/authSlice";
import LessonScheduler from "./LessonScheduler";
import {join} from "../api/schedule";

const TeacherStatus = () => {
    const teacher = useAppSelector(selectTeacher);
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();

    const [connectionWait, setConnectionWait] = useState<boolean>(false);

    const [timer, setTimer] = useState<NodeJS.Timer | null>(null);

    const joinToMeet = (teacherId: string) => {
        setConnectionWait(true);
        join({teacherId}).then((result) => {
            window.location.href = result.link;
        }).finally(() => {
        })
    }

    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
                console.log('clear')
            }
        }

    })


    useEffect(() => {
        if (teacher && !teacher.meet?.running) {
            setTimer(setTimeout(() => dispatch(fetchTeacher({id: teacher._id})), 60000));
        }
    }, [dispatch, teacher])

    return teacher && (<Grid spacing={2} container>
        {<Grid item sm={12} xs={12}>

            {teacher.meet && teacher.meet.running ? <Card variant={'outlined'}>
                    <CardContent>
                        <h4>Сейчас идет занятие</h4>
                        <Grid spacing={2} display={'flex'} alignItems={'center'} container>
                            <Grid item sm={8}>
                                <Chip color={'success'} label="Онлайн"/>
                                <Chip style={{marginLeft: 10}}
                                      label={'началось в ' + moment(teacher.meet?.createTime).format('HH:mm')}/>
                                <Chip style={{marginLeft: 10}} label={<div
                                    style={{display: 'flex', alignItems: 'center'}}>{teacher.meet?.participantCount}
                                    <PeopleOutline style={{marginLeft: 2}}/></div>}/>
                            </Grid>
                            {teacher._id !== user?._id &&
                                <Grid display={'flex'} alignItems={'center'} justifyContent={'end'} item sm={4}>
                                    {!connectionWait ?
                                        <Button onClick={() => joinToMeet(teacher._id)} variant={'contained'}>
                                            Подключиться
                                        </Button> : <CircularProgress size={30}/>}
                                </Grid>}
                        </Grid>


                    </CardContent>
                </Card> :
                <Card variant={'outlined'}>
                    <CardContent>
                        <h4>Учитель не в классе</h4>
                        <p>Когда учитель войдет в класс, вы увидете кнопку для подключения ниже.</p>
                        <CircularProgress size={30}/>
                    </CardContent>
                </Card>}
        </Grid>}

    </Grid>)
}

export default TeacherStatus;
