import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "./store";
import axiosApp from "../axiosApp";
import {confirmCode, getUser, sendCode, updateUser} from "../api/auth";
import {User} from "../api/types/authApiTypes";
import {getTeacher, getTeachers} from "../api/student";
import {authSlice} from "./authSlice";

interface StudentState {
    teachers: User[],
    currentTeacher: User | null,
    loading: boolean
}

const initialState: StudentState = {
    teachers: [],
    currentTeacher: null,
    loading: true
}

export const studentState = createSlice({
    reducers: {
        clearTeacher: (state) => {
            state.currentTeacher = null;
        },
    },
    name: 'student',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchTeachers.fulfilled, (state, action) => {
            state.teachers = action.payload;
            state.loading = false;
        });

        builder.addCase(fetchTeacher.fulfilled, (state, action) => {
            state.currentTeacher = action.payload;
        })
    },
})

export const fetchTeachers = createAsyncThunk(
    'getTeachers',
    getTeachers
)

export const fetchTeacher = createAsyncThunk(
    'getTeacher',
    getTeacher
)
export const { clearTeacher } = studentState.actions
export const selectTeachers = (state: RootState) => state.student.teachers;
export const selectTeacher = (state: RootState) => state.student.currentTeacher;
export const selectStudentIsLoading = (state: RootState) => state.student.loading;

export default studentState.reducer

