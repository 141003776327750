import axios from "axios";
import {confirmCodeResult, sendCodeResult, User, UserUpdate} from "./types/authApiTypes";

export const endpoint: string = 'https://api.edukesha.ru/';



export const sendCode = async ({phone}: {phone: string}) => {
    return (await axios.post<sendCodeResult>(endpoint + 'auth', {phone: phone.replace(/[\s+]/g, '')})).data
}


export const confirmCode = async ({smsToken, code}: {smsToken: string, code: string}) => {
    return (await axios.post<confirmCodeResult>(endpoint + 'confirm', {token: smsToken, code: code.replace(/[\s+]/g, '')})).data
};


export const getUser = async () => {
    return (await axios.get<User>(endpoint + 'user')).data
};

export const updateUser = async (data: UserUpdate) => {
    return (await axios.post<User>(endpoint + 'user', data)).data
};
