import React, {useEffect} from "react";
import LoginForm from "./LoginForm";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Button,
    Card,
    CardContent,
    Container,
    Grid,
    Typography
} from "@mui/material";
import {useAppSelector} from "../store/store";
import {selectUser} from "../store/authSlice";
import {useNavigate} from "react-router-dom";
const WelcomePage = () => {
    const user = useAppSelector(selectUser);
    const navigate = useNavigate();

    return ( <div className="App">
        <Container>
            <Grid spacing={2} container>
                <Grid item justifyContent={'center'} sm={6} xs={12}>
                    <Card variant={'outlined'}>
                        <CardContent>
                            <Typography typography={'h6'} mb={2}>Часто зававаемые вопросы</Typography>
                            <Accordion variant={'outlined'}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Для кого?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Система для создана для учителей и учеников.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion variant={'outlined'}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Какое максимальное кол-во  может заниматься?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                       25 человек в одной комнате
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item justifyContent={'center'} sm={6} xs={12}>
                    <Card variant={'outlined'}>
                        <CardContent>
                            {!user ? <LoginForm/> : <div>
                                <h4>Добро пожаловать, {user.firstName}</h4>
                                <Button onClick={() => {navigate('/lk')}} variant={'contained'}>Перейти в личный кабинет</Button>
                            </div>}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>




        {/*<input className={'input'} type="text"/>*/}


    </div>);
}

export default WelcomePage;
