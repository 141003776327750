import {Button, Container, Grid, Hidden, IconButton, Stack} from "@mui/material";
import React from "react";
import {Logout} from "@mui/icons-material";
import {useAppSelector} from "../store/store";
import {selectUser} from "../store/authSlice";

const Header = () => {
    const user = useAppSelector(selectUser);
    return <Container>
        <Stack style={{background: '#f6f6f6', padding: 20, marginTop: 20, marginBottom: 20, borderRadius: 20}} alignItems={'center'}
               direction={'row'} spacing={2}>
            <img style={{width: 64}} src="/logo.svg" alt=""/>
            <h1>EduКеша</h1>
            <Hidden only={'xs'}>
                <div style={{borderLeft: '1px solid #0f0f0f', padding: 5, paddingLeft: 20, marginLeft: 20}}>
                    эффективный дистант
                </div>
            </Hidden>
            {user && <IconButton  style={{float: 'right'}} onClick={() => {
                localStorage.clear();
                window.location.reload()
            }}>
                <Logout/>
            </IconButton> }
        </Stack>


    </Container>
};

export default Header;
