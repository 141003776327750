import {
    Avatar,
    Badge, CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemAvatar, ListItemButton,
    ListItemText, Stack,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {PeopleOutline} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "../store/store";
import {fetchTeachers, selectTeachers} from "../store/studentSlice";
import {join, makeSchedule} from "../api/schedule";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {Skeleton} from "@mui/lab";

const TeacherList = () => {

    const teachers = useAppSelector(selectTeachers);
    const [filterText, setFilterText] = useState<string>('');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchTeachers())
    }, [])

    return (<div style={{background: "#efefef", padding: 10, borderRadius: 20, marginTop: 20}}>
        <h4>Учителя</h4>
        <TextField onChange={(e) => setFilterText(e.target.value.trim().toLowerCase())} placeholder={'Поиск'}
                   fullWidth={true} style={{background: 'white'}}/>
        <List>
            {teachers.length > 0 ? teachers.filter((t) => {
                if (!filterText) {
                    return true;
                }
                return (t.firstName + t.lastName + t.thirdName).toLowerCase().indexOf(filterText) !== -1
            }).map(teacher => <ListItem key={teacher._id} secondaryAction={
                <Typography>
                    {teacher?.meet?.running && <Typography display={'flex'} alignItems={'center'}>
                        {teacher.meet?.participantCount}
                        <PeopleOutline style={{marginLeft: 2}}/>
                    </Typography>}

                </Typography>
            } alignItems="center">

                <ListItemButton onClick={() => {
                    navigate('/teacher/' + teacher._id);
                }}>
                    <ListItemAvatar>
                        <Badge overlap={'circular'} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                               color={teacher.meet?.running ? 'success' : 'default'} badgeContent=" " variant="dot">
                            <Avatar
                                src={`https://eu.ui-avatars.com/api/?name=${teacher.lastName + ' ' + teacher.firstName}`}/>
                        </Badge>
                    </ListItemAvatar>
                    <ListItemText
                        primary={teacher.lastName + ' ' + teacher.firstName + ' ' + teacher.thirdName}
                    />
                </ListItemButton>
            </ListItem>) : <div>
                {[1, 2, 3, 4, 5].map(() => <Stack marginTop={2} paddingX={3} spacing={2} direction={'row'}>
                    <Skeleton variant="circular">
                        <Avatar />
                    </Skeleton>
                    <Skeleton width={'100%'}/>
                </Stack>)}
            </div>}

        </List></div>);
}

export default TeacherList;
