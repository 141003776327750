import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../store/store";
import {selectAuthLoading, selectReady, selectUser} from "../store/authSlice";
import {Button, Card, CardContent, Container, Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ProfileEdit from "./ProfileEdit";
import {CardTravel} from "@mui/icons-material";
import LessonScheduler from "./LessonScheduler";
import TeacherList from "./TeacherList";
import {fetchTeachers} from "../store/studentSlice";
const LKPage = () => {
    const user = useAppSelector(selectUser);
    const ready =  useAppSelector(selectReady);
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if(ready && !user?._id){
            navigation('/')
        } else {
            if(user?.role === 'teacher'){
                navigation('/teacher/'+user._id);
            }
            dispatch(fetchTeachers())
        }
    }, []);
    return <Container>
        <h2>{user?.lastName} {user?.firstName} {user?.thirdName}</h2>

        {!user?.need_complete_account && <Grid spacing={2} container>
            <Grid item justifyContent={'center'} sm={6} xs={12}>
                {user?.role === 'teacher' && <LessonScheduler/>}
            </Grid>
            <Grid item justifyContent={'center'} sm={12} xs={12}>
                {user?.role === 'student' &&  <TeacherList/>}
            </Grid>
        </Grid> }

        {/*{user?.role === 'teacher' && <Card>*/}
        {/*    <CardContent>*/}
        {/*        <h3>Инструкция</h3>*/}
        {/*        <p>Вы в интерфейсе учителя. Для того, чтобы к вам смогли подключиться дети введит <b>Название урока</b>*/}
        {/*            &nbsp;и введите <b>Дату занятия</b>. Минимальное время начала занятия - <b>через 15 минут</b>*/}
        {/*        </p>*/}
        {/*    </CardContent>*/}
        {/*</Card>}*/}

        {user?.need_complete_account && <ProfileEdit/>}
    </Container>
}

export default LKPage;
