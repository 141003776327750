import axiosApp from "../axiosApp";
import {endpoint} from "./auth";
import {CreateSchedule} from "./types/scheduleApiTypes";


export const getTeachers = async () => {
    return (await axiosApp.get(endpoint + 'teachers')).data
}

export const getTeacher = async ({id}: {id: string}) => {
    return (await axiosApp.get(endpoint + 'teacher/'+id)).data
}

export const getSessions = async ({id}: {id: string}) => {
    return (await axiosApp.get(endpoint + 'sessions/'+id)).data
}
