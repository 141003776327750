import {Alert, Button, CircularProgress, FormControlLabel, Stack, Switch, TextField, Typography} from "@mui/material";
import {
    fetchCode,
    fetchConfirmCode,
    fetchUser, selectAuthLoading,
    selectCode, selectCodeError, selectPhone,
    selectSmsToken,
    selectUser,
    setCode, setPhone
} from "../store/authSlice";
import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../store/store";
import {useNavigate} from "react-router-dom";
import InputMask from "react-input-mask";

const LoginForm = () => {
    const [phoneChanged, setPhoneChanged] = useState<boolean>(false);
    const code = useAppSelector(selectCode);
    const phone = useAppSelector(selectPhone);
    const codeError = useAppSelector(selectCodeError);
    const smsToken = useAppSelector(selectSmsToken);
    const loading = useAppSelector(selectAuthLoading);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
    <Stack direction={'column'}>
        <h4>Вход в систему</h4>
        <InputMask
            mask="+7 999 999 99 99"
            value={phone}
            onChange={(e) => {dispatch(setPhone(e.target.value)); setPhoneChanged(true);}}
            disabled={loading}
        >
            {() => <TextField  variant={'outlined'} placeholder={'Номер телефона'}/>}
        </InputMask>
        {loading && <CircularProgress size={30} />}
        {!phoneChanged && smsToken  && <Stack mt={3}>
            {!codeError ? <Alert severity={'info'}>
                Сейчас вам позвонит робот, введите последние 4 цифры его номера телефона
            </Alert> : <Alert severity="error">{codeError}</Alert>}
            <TextField inputMode={'numeric'} style={{marginTop: 10}} error={!!codeError}   label={'Последние 4 цифры номера'}  variant={'outlined'} disabled={!smsToken || loading} onChange={(e) => dispatch(setCode(e.target.value))} placeholder={'Код подтверждения'}/>

        </Stack>}
        <br/>
        <Button style={{width: '100%'}} variant={'contained'} onClick={() => {setPhoneChanged(false); (!code || phoneChanged) ? dispatch(fetchCode({phone})) : dispatch(fetchConfirmCode({smsToken, code})).then(() => dispatch(fetchUser()).then(() => {navigate('/lk')}))}}  className={'button button__rep'}>
            Продолжить
        </Button>
    </Stack>
    );
};

export default LoginForm
