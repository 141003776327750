import React, {useEffect, useState} from 'react';
import {getSessions} from "../api/student";
import {
    Avatar,
    Badge,
    Card,
    CardContent,
    Chip, Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack
} from "@mui/material";
import moment from "moment";
import {User} from "../api/types/authApiTypes";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Alert} from "@mui/lab";

export type Props = {
    teacherId: string
}

export type Message = {
    message: string,
    sender: {
        name: string
    }
}
export type File = {
    name: string,
    path: string
}

export type Session = {
    createTime: string,
    finishedAt: string,
    participants: User[],
    messages: Message[],
    files: File[]
}
const SessionList = (props: Props) => {
    const [sessions, setSessions] = useState<Session[]>([]);
    useEffect(() => {
        getSessions({id: props.teacherId}).then((r) => {
            setSessions(r);
        })
    }, [props]);
    return <div>
        {sessions.length > 0 ? sessions.map(s => {
            return <Card style={{marginTop: 20}}>
                <CardContent>
                    <h4>Занятие {moment(s.createTime).format('DD.MM HH:mm')} - {moment(s.finishedAt).format('HH:mm')}</h4>



                    <Chip style={{margin: 3}} color={'success'} label={moment(s.finishedAt).diff(s.createTime, 'm') + ' минут'}/>
                    <Chip style={{margin: 3}}   label={s.participants?.length + ' участник'}/>
                    {s.participants?.length > 0 && s.participants.map(p => <Chip style={{margin: 3}} label={p.firstName + ' ' + p.lastName}/>)}
                    {s.files?.length > 0 && <div>
                        <Typography mt={2} mb={2}>Презентации урока</Typography>
                        {s.files.map(f => <Link target={'_blank'} href={f.path} mr={2}>{f.name}</Link>)}
                    </div>}

                </CardContent>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Чат урока</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {s.messages?.length > 0 && s.messages.map((message) => <ListItem>
                                <ListItemAvatar>
                                    <Avatar
                                        src={`https://eu.ui-avatars.com/api/?name=${message.sender.name}`}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={message.sender.name}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                <div dangerouslySetInnerHTML={{__html: message.message}}/>
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>)}
                        </List>
                    </AccordionDetails>
                </Accordion>

            </Card>
        }) : <Alert severity="info">Занятий еще не было или они не успели подгрузиться.</Alert>}
    </div>
}

export default SessionList;
