import {Alert} from "@mui/lab";
import {Button, Card, CardContent, CircularProgress} from "@mui/material";
import React, {useState} from "react";

import 'moment/locale/ru'
import moment from "moment";
import {makeSchedule} from "../api/schedule";  // without this line it didn't work
moment.locale('ru')


const LessonScheduler = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string|null>(null);
    const create = () => {
        setLoading(true);
        makeSchedule({scheduleName: 'тест', scheduleDate: moment(scheduleDate).toISOString()}).then((result) => {
            window.location.href = result.link;
            setLoading(false)
        }).catch((e) => {
           setError(e.response?.data);
        }).finally(() => {
            setLoading(false)
        })
    };

    const date = moment().add(15, 'minute').toDate()
    const [scheduleDate, setScheduleDate] = React.useState<Date|null>(date);
    return <Card variant={'outlined'} style={{marginTop: 20}}>
        <CardContent>
            {error && <Alert severity="error" sx={{ width: '100%' }}>
                {error}
            </Alert> }
            <p>
                Чтобы подключиться к уроку зайдите в класс по кнопке ниже.
            </p>
            {loading ? <CircularProgress size={30} /> :  <Button onClick={() => create()} variant={'contained'}>Зайти в класс</Button>}

        </CardContent>
    </Card>
}

export default LessonScheduler;
