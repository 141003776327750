import axiosApp from "../axiosApp";
import {endpoint} from "./auth";
import {CreateSchedule, JoinSchedule} from "./types/scheduleApiTypes";


export const makeSchedule = async (data: CreateSchedule) => {
    return (await axiosApp.post(endpoint + 'schedule', data)).data
}


export const join = async (data: JoinSchedule) => {
    return (await axiosApp.post(endpoint + 'join', data)).data
}
